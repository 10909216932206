<template>
  <OnSaleMB v-if="layout == 4" />
  <OnSalePc v-else />
</template>

<script>
import OnSaleMB from "@/pages/mb/home/OnSale";
import OnSalePc from "@/pages/pc/home/OnSale";
import { provide, reactive } from "vue";
import BrandServices from "@/services/brand";
import BrandPromotionServices from "@/services/brandpromotion";
import ProductService from "@/services/product";

export default {
  name: "on-sale",
  components: {
    OnSaleMB,
    OnSalePc,
  },
  setup() {
    const state = reactive({
      sale_shocks: [],
      sale_brands: [],
      sale_products_1030: [],
      sale_products_3050: [],
      sale_products_5080: [],
    });

    onIntersectionBrandPromotion();

    async function onIntersectionCompaniesSale() {
      const response = await BrandServices.fetchBrandsSale();
      if (response && response.data) {
        state.sale_brands = response.data.companies;
      }
    }

    async function onIntersectionBrandPromotion() {
      const response = await BrandPromotionServices.fetchBrandsPromotions();
      if (response && response.data) {
        state.sale_shocks = response.data.brand_promotions;
      }
    }

    async function onIntersectionProducts1030() {
      const response = await ProductService.fetchProducts({
        on_sale_from: 10,
        on_sale_to: 30,
        vendor_only: true,
        sort_by: "random",
        limit: 30,
        use_caching: true,
      });
      if (response && response.data) {
        state.sale_products_1030 = response.data.products;
      }
    }

    async function onIntersectionProducts3050() {
      const response = await ProductService.fetchProducts({
        on_sale_from: 30,
        on_sale_to: 50,
        vendor_only: true,
        sort_by: "random",
        limit: 30,
        use_caching: true,
      });
      if (response && response.data) {
        state.sale_products_3050 = response.data.products;
      }
    }

    async function onIntersectionProducts5080() {
      const response = await ProductService.fetchProducts({
        on_sale_from: 50,
        on_sale_to: 80,
        vendor_only: true,
        sort_by: "random",
        limit: 30,
        use_caching: true,
      });
      if (response && response.data) {
        state.sale_products_5080 = response.data.products;
      }
    }

    provide("state", state);
    provide("onIntersectionCompaniesSale", onIntersectionCompaniesSale);
    provide("onIntersectionBrandPromotion", onIntersectionBrandPromotion);
    provide("onIntersectionProducts1030", onIntersectionProducts1030);
    provide("onIntersectionProducts3050", onIntersectionProducts3050);
    provide("onIntersectionProducts5080", onIntersectionProducts5080);
  },
};
</script>
