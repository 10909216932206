<template>
  <section class="page-section product-bestselling-section" v-if="!brands">
    <div class="home_section-inner">
      <Skeletor class="section-title" width="30%" />
      <div class="banner_focus-body">
        <div class="banner_focus-item" v-for="i in 2" :key="i">
          <Skeletor width="100%" height="278" />
        </div>
      </div>
    </div>
  </section>

  <div v-else-if="brands.length" :class="wrapperClass">
    <div class="section-title-wrapper">
      <h2 class="section-title" v-text="title"></h2>

      <div class="product-see-all">
        <router-link
          :to="{ name: 'products-shock' }"
          class="primary-button"
          title="Xem tất cả"
        >
          <span class="primary-link_text">Xem tất cả</span>
          <i class="dsi dsi-act-arrow-forward"></i>
        </router-link>
      </div>
    </div>

    <div class="dosiin_position-relative">
      <Swiper
        class="dosiin_home_sale overflow dosiin_position-unset"
        :modules="modules"
        :slidesPerView="options.slidesPerView"
        :slidesPerGroup="options.slidesPerGroup"
        :spaceBetween="options.spaceBetween"
        :navigation="options.navigation"
      >
        <SwiperSlide v-for="(event, i) in brands.slice(0, 9)" :key="i">
          <div class="brand-suggested-brand-item_wrapper dosiin_position-relative">
            <div class="brand-item_header">
              <a
                :href="_brandUrl(event.company_data.seo_name)"
                :title="event.name"
                style="display: block"
              >
                <div class="brand-item_banner-wrapper home-sale-img">
                  <div class="brand-item_img-overlay">
                    <img
                      v-lazy="{
                        src: _bannerImage(event.company_data.banners_main),
                        loading: _bannerDefault,
                        error: _bannerDefault,
                      }"
                      width="672"
                      height="278"
                      class="brand-list-item_img"
                      style="height: 278px; object-fit: cover"
                    />
                  </div>
                </div>
              </a>
            </div>
            <div class="home_sale-promo">
              <div class="sale_promo-info">
                <router-link :to="_brandUrl(event.company_data.seo_name)">
                  <img
                    :width="40"
                    :height="40"
                    v-lazy="_companyAvatar(event.company_data.logos.theme)"
                    :alt="event.company_data.company"
                  />
                  <span v-text="event.company_data.company"></span>
                </router-link>
              </div>
              <div class="brand-item_promo">
                <i class="promo-icon"></i>
                <router-link :to="_brandUrl(event.company_data.seo_name)">
                  {{ event.name }}
                </router-link>
              </div>
              <div class="brand-item_promo">
                <i class="dsi dsi-s24 dsi-clock"></i>
                <span class="dosiin_mr-1">Từ</span>
                {{ _momentDateMonthYear(event.time_start) }}
                <span class="dosiin-4">đến </span>
                {{ _momentDateMonthYear(event.time_end) }}
              </div>
            </div>
          </div>
        </SwiperSlide>

        <div
          v-if="options.navigation"
          class="swiper-button-next dosiin_swiper-button dosiin_position-button-next"
        ></div>
        <div
          v-if="options.navigation"
          class="swiper-button-prev dosiin_swiper-button dosiin_position-button-prev"
        ></div>
      </Swiper>
    </div>
  </div>
</template>

<script>
import { toRefs, ref, watch } from "vue";
import { Pagination, Navigation } from "swiper";
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    wrapperClass: {
      type: String,
      default: "",
    },
    brands: {
      type: Array,
      require: true,
      default: [],
    },
    options: {
      type: Object,
      default: {
        slidesPerView: 2,
        spaceBetween: 16,
        slideToClickedSlide: false,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    },
  },
  setup(props) {
    const options = {};
    const skeleton = ref(null);

    return {
      options,
      skeleton,
      ...toRefs(props),
      modules: [Pagination, Navigation],
    };
  },
};
</script>
<style>
.dosiin-tab-bar-pc .tab-bar-content-item .tab-bar-content-item-detail {
  letter-spacing: 1.5px;
}
</style>
