<template>
  <div class="dosiin_page-min-height dosiin_page-max-width dosiin_padding-header-cat-top">
    <HotDealPC />

    <Intersection @onIntersection="onIntersectionBrandPromotion()">
      <BrandSliderShock
        wrapperClass="page-section dosiin_mt-16"
        title="Khuyến mãi sốc"
        :brands="state.sale_shocks"
      />
    </Intersection>

    <div ref="promotionsBox">
      <Intersection @onIntersection="onIntersectionCompaniesSale()">
        <BrandSliderSale
          wrapperClass="page-section"
          title="Giảm giá cho bạn"
          :brands="state.sale_brands"
          @changeQuery="changeQuery"
        />
      </Intersection>
    </div>

    <Intersection @onIntersection="onIntersectionProducts1030()">
      <ProductsOnSaleSlider
        wrapperClass="page-section"
        title="Giảm giá 10-30%"
        :link="`/products?on_sale_from=10&on_sale_to=30`"
        banner_discount="https://dosi-in.com/images/assets/icons/discount-0--30.jpg"
        gridClass="grid-20"
        :products="state.sale_products_1030"
        :options="{
          slidesPerView: 5,
          spaceBetween: 16,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        }"
      />
    </Intersection>

    <Intersection @onIntersection="onIntersectionProducts3050()">
      <ProductsOnSaleSlider
        wrapperClass="page-section"
        title="Giảm giá 30-50%"
        :link="`/products?on_sale_from=30&on_sale_to=50`"
        banner_discount="https://dosi-in.com/images/assets/icons/discount-30-50.jpg"
        gridClass="grid-20"
        :products="state.sale_products_3050"
        :options="{
          slidesPerView: 5,
          spaceBetween: 16,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        }"
      />
    </Intersection>

    <Intersection @onIntersection="onIntersectionProducts5080()">
      <ProductsOnSaleSlider
        wrapperClass="page-section"
        title="Giảm giá 50-80%"
        :link="`/products?on_sale_from=50&on_sale_to=80`"
        banner_discount="https://dosi-in.com/images/assets/icons/discount-50-80.jpg"
        gridClass="grid-20"
        :products="state.sale_products_5080"
        :options="{
          slidesPerView: 5,
          spaceBetween: 16,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        }"
      />
    </Intersection>
  </div>
</template>

<script>
import { ref, inject, watch } from "vue";
import HotDealPC from "@/components/events/HotDealPC";
import ProductsOnSaleSlider from "@/components/sliders/ProductsOnSale";
import { useRoute, useRouter } from "vue-router";
import BrandServices from "@/services/brand";
import BrandSliderShock from "@/components/sliders/BrandsShockSale";
import BrandSliderSale from "@/components/sliders/BrandsSaleOff";

export default {
  name: "on-sale",
  components: {
    ProductsOnSaleSlider,
    HotDealPC,
    BrandSliderShock,
    BrandSliderSale,
  },
  setup() {
    const currentUser = inject("currentUser");
    const state = inject("state");
    const onIntersectionCompaniesSale = inject("onIntersectionCompaniesSale");
    const onIntersectionBrandPromotion = inject("onIntersectionBrandPromotion");
    const onIntersectionProducts1030 = inject("onIntersectionProducts1030");
    const onIntersectionProducts3050 = inject("onIntersectionProducts3050");
    const onIntersectionProducts5080 = inject("onIntersectionProducts5080");
    const router = useRouter();
    const route = useRoute();
    const promotionsBox = ref(null);

    const changeQuery = (query) => {
      changeBrandDiscount(query);
    };

    async function changeBrandDiscount(query) {
      if (query) {
        state.sale_brands = "";
        router.replace({ query: { sale_from: query.sale_from, sale_to: query.sale_to } });
        const response = await BrandServices.fetchBrandsSale(query);
        if (response && response.data) {
          state.sale_brands = response.data.companies;
          return state.sale_brands;
        }
      }
    }

    watch(
      () => route.query,
      () => {
        window.scrollTo(0, promotionsBox.value.offsetTop);
      }
    );

    return {
      state,
      route,
      router,
      currentUser,
      onIntersectionCompaniesSale,
      onIntersectionBrandPromotion,
      onIntersectionProducts1030,
      onIntersectionProducts3050,
      onIntersectionProducts5080,
      changeQuery,
      promotionsBox,
    };
  },
};
</script>
<style>
.brand_on-promo .brand-list .brand-modal_item {
  width: 23% !important;
}
.brand_on-promo .time-period_item {
  cursor: pointer;
}
.brand-suggested-brand-item_wrapper .brand-suggested-brand-item_banner-wrapper a {
  height: 137px;
  display: block;
}
</style>
