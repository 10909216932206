<template>
  <section class="page-section product-bestselling-section" v-if="brands.length === 0">
    <div class="home_section-inner">
      <Skeletor class="section-title" width="30%" />
      <div class="banner_focus-body">
        <div class="banner_focus-item" v-for="i in 2" :key="i">
          <Skeletor width="100%" height="278" />
        </div>
      </div>
    </div>
  </section>

  <div v-else :class="wrapperClass">
    <div class="section-title-wrapper">
      <h2 class="section-title">Giảm giá cho bạn</h2>

      <div class="dosiin_slider-controls">
        <div
          class="dosiin_slider-pagination-brands-sale swipable-slider_pagination swiper-button-pagination swiper-filter-nav-bar"
          :class="paginationBrandSale"
        ></div>
      </div>
    </div>

    <div class="dosiin-tab-bar-pc">
      <div class="tab-bar-pc-content">
        <div class="tab-bar-pc-line"></div>
        <div class="tab-bar-content-item tab-bar-no-padding">
          <a
            class="tab-bar-content-item-detail"
            :class="
              Object.keys(route.query).length == 0 || route.query.sale_to == '30'
                ? 'active line-active'
                : ''
            "
            @click="$emit('changeQuery', { sale_from: '10', sale_to: '30' })"
            >10% - 30%</a
          >
          <a
            class="tab-bar-content-item-detail"
            :class="route.query.sale_to == '50' ? 'active line-active' : ''"
            @click="$emit('changeQuery', { sale_from: '30', sale_to: '50' })"
            >30% - 50%</a
          >
          <a
            class="tab-bar-content-item-detail"
            :class="route.query.sale_to == '80' ? 'active line-active' : ''"
            @click="$emit('changeQuery', { sale_from: '50', sale_to: '80' })"
            >50% - 80%</a
          >
        </div>
        <div class="tab-bar-pc-line"></div>
      </div>
    </div>

    <div class="dosiin_mt-16" v-if="brands">
      <div class="dosiin_position-relative">
        <div class="brand-item-promotion">
          <Swiper
            class="list-brand-promotion dosiin_position-unset"
            :modules="modules"
            :slidesPerView="options.slidesPerView"
            :slidesPerGroup="options.slidesPerGroup"
            :spaceBetween="options.spaceBetween"
            :navigation="options.navigation"
            :pagination="pagination"
          >
            <SwiperSlide v-for="(brand, i) in brands.slice(0, 14)" :key="i">
              <div class="brand-suggested-brand-item_wrapper brand-item">
                <div class="brand-suggested-brand-item_banner-wrapper">
                  <a
                    :href="_brandUrl(brand.company_data.seo_name)"
                    :title="brand.company"
                  >
                    <img
                      v-lazy="{
                        src: _bannerImage(brand.company_data.banners_main),
                        loading: _bannerDefault,
                        error: _bannerDefault,
                      }"
                      width="330"
                      height="137"
                      class="ty-pict brand-suggested-brand-item_banner cm-image pict lazyloaded"
                    />
                  </a>
                </div>
                <div class="brand-suggested-brand-item_body dosiin_mb-4">
                  <div
                    class="brand-item_img-wrapper brand-suggested-brand-item_img-wrapper"
                  >
                    <a
                      :href="_brandUrl(brand.company_data.seo_name)"
                      :title="brand.company"
                    >
                      <BrandLogo
                        :brand="brand.company_data"
                        :width="76"
                        :height="76"
                        class="ty-pict brand-suggested-brand-item_img cm-image pict lazyloaded"
                      />
                    </a>
                  </div>
                  <div class="brand-suggested-brand-item_info" style="min-height: 51px">
                    <a
                      :href="_brandUrl(brand.company_data.seo_name)"
                      :title="brand.company"
                    >
                      <h5 class="brand-item_title" v-text="brand.company"></h5>
                    </a>
                    <dl class="brand-item_social">
                      <span class="dosiin_brand-follow-number">
                        <i class="dsi dsi-act-fill-coupons"></i> {{ brand.percent }}%
                      </span>
                      <div class="quantity-product">
                        <b>{{ brand.company_data.products_count }}</b
                        >sản phẩm
                      </div>
                    </dl>
                  </div>
                </div>
                <div class="brand-suggested-brand-item_follow">
                  <FollowButton
                    @update:modelValue="(newValue) => (brand.follow_count = newValue)"
                    class="brand_follow-btn dosiin_brand_follow-btn primary-button button follow-with-text-btn"
                    followable_type="brand"
                    :followable_id="brand.company_id"
                    :is_follow="brand.is_follow"
                  />
                  <a
                    class="brand-visit-btn"
                    :href="_brandUrl(brand.company_data.seo_name)"
                  >
                    <div class="inner-btn">
                      <span>Cửa hàng</span><i class="dsi dsi-s16 dsi-act-manage"></i>
                    </div>
                  </a>
                </div>
              </div>
            </SwiperSlide>

            <div
              v-if="options.navigation"
              class="swiper-button-next dosiin_swiper-button dosiin_position-button-next"
            ></div>
            <div
              v-if="options.navigation"
              class="swiper-button-prev dosiin_swiper-button dosiin_position-button-prev"
            ></div>
          </Swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs, ref, computed } from "vue";
import { Pagination, Navigation } from "swiper";
import { useRoute, useRouter } from "vue-router";
import BrandServices from "@/services/brand";

export default {
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    wrapperClass: {
      type: String,
      default: "",
    },
    brands: {
      type: Array,
      require: true,
      default: [],
    },
    options: {
      type: Object,
      default: {
        slidesPerView: 4,
        spaceBetween: 9,
        slideToClickedSlide: false,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();

    const options = {};
    const skeleton = ref(null);
    const paginationBrandSale =
      "dosiin_slider-pagination-brands-sale-" +
      btoa(Math.random().toString()).substr(10, 5);
    const pagination = {
      el: "." + paginationBrandSale,
      clickable: true,
      renderBullet: (index, className) =>
        `<span data="${index}" class="swipable-slider_indicator ${className}"></span>`,
    };

    return {
      options,
      skeleton,
      paginationBrandSale,
      ...toRefs(props),
      pagination,
      modules: [Pagination, Navigation],
      router,
      route,
    };
  },
};
</script>
