<template>
  <div v-if="!dosiinEmptyObject(state.event)" class="hotdeal-section dosiin_mb-16">
    <div class="hotdeal-section-inner">
      <div class="hotdeal-brand-wrapper">
        <div class="hotdeal_ttl-left d-flex align-items-center">
          <h2 class="hotdeal_title">
            <i class="dsi dsi-s24 dsi-color-thunder"> </i>
            <span class="text-pink-gradient">Sale độc quyền</span>
          </h2>
          <div class="hotdeal-brand dosiin_mr-2">
            <div class="hotdeal-brand_img-wrapper">
              <template v-for="(brand, i) in state.brands">
                <div
                  class="brand_img"
                  :class="['brand_img-' + (i + 1)]"
                  :key="i"
                  v-if="i < 4"
                >
                  <BrandLogo
                    :brand="brand"
                    :width="24"
                    :height="24"
                    imageClass="brand-new-brand_img"
                  />
                  <span v-if="i === 3 && state.brands_count > 4"
                    ><i class="dsi dsi-more-h"></i
                  ></span>
                </div>
              </template>
            </div>
          </div>
          <div class="qtl_brand" v-if="state.event.companies != 0">
            {{ state.brands_count }}<span>thương hiệu</span>
          </div>
        </div>
        <div class="bg_product-blur">
          <div class="bg_product-blur-item blur-item-left"></div>
          <router-link
            :to="{ name: 'exclusive', query: { event_id: state.event.id } }"
            :title="state.event.name"
          >
            <h3 v-text="state.event.name"></h3>
          </router-link>
          <div class="bg_product-blur-item blur-item-right"></div>
        </div>
        <div class="hotdeal_ttl-right d-flex align-items-center">
          <vue-countdown
            :time="
              state.event.time_end * 1000 - currentTime > 0
                ? state.event.time_end * 1000 - currentTime
                : 0
            "
            v-slot="{ days, hours, minutes, seconds }"
          >
            <ul class="dosiin_countdown hotdeal-countdown">
              <li class="dosiin_countdown_days">
                <div class="number" v-text="_addLeadingZero(days)"></div>
              </li>
              <li class="dosiin_countdown_hours">
                <div class="number" v-text="_addLeadingZero(hours)"></div>
              </li>
              <li class="dosiin_countdown_minutes">
                <div class="number" v-text="_addLeadingZero(minutes)"></div>
              </li>
              <li class="dosiin_countdown_seconds">
                <div class="number" v-text="_addLeadingZero(seconds)"></div>
              </li>
            </ul>
          </vue-countdown>
          <div class="hotdeal_btn-wrapper">
            <router-link
              :to="{ name: 'exclusive', query: { event_id: state.event.id } }"
              class="primary-button"
              :title="state.event.name"
            >
              <span class="primary-link_text">Xem toàn bộ</span
              ><i class="dsi dsi-act-arrow-forward"></i>
            </router-link>
          </div>
        </div>
      </div>
      <div class="home_product-inner">
        <div class="home_product-list">
          <div class="home_product-box dosiin_position-relative">
            <Swiper
              class="dosiin_position-unset"
              :modules="modules"
              :slidesPerView="5"
              :slidesPerGroup="5"
              :spaceBetween="16"
              :navigation="{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }"
            >
              <SwiperSlide
                class="swiper-slide"
                v-for="(product, i) in state.products"
                :key="i"
              >
                <ProductItem :product="product" />
              </SwiperSlide>
              <SwiperSlide class="swiper-slide last-slide">
                <div class="seemore-btn">
                  <i class="dsi dsi-act-arrow-forward" style="margin: auto"></i>
                  <router-link
                    :to="{ name: 'exclusive', query: { event_id: state.event.id } }"
                    class="primary-button"
                    :title="state.event.name"
                  >
                    <span class="primary-link_text">Xem thêm</span>
                  </router-link>
                </div>
              </SwiperSlide>

              <div
                class="swiper-button-next dosiin_swiper-button dosiin_position-button-next"
              ></div>
              <div
                class="swiper-button-prev dosiin_swiper-button dosiin_position-button-prev"
              ></div>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, inject } from "vue";
import { Navigation } from "swiper";
import HotDealSkeleton from "@/components/skeletons/events/HotDealPC";
import ProductItem from "@/components/product/ProductItem";
import EventData from "@/services/event";
export default {
  components: {
    HotDealSkeleton,
    ProductItem,
  },
  setup() {
    const currentTime = inject("currentTime");
    const state = reactive({
      loading: true,
      brands: [],
      event: {},
      products: [],
      brands_count: 0,
    });
    getExclusive();
    async function getExclusive() {
      const response = await EventData.fetchExclusive();
      if (response.data) {
        state.loading = false;
        state.event = response.data.event;
        state.brands_count = response.data.event
          ? response.data.event.companies_count
          : 0;
        state.products = response.data.products;
        state.brands = response.data.event
          ? response.data.event.companies.slice(0, 4)
          : [];
      }
    }

    return {
      state,
      currentTime,
      modules: [Navigation],
    };
  },
};
</script>
