<template>
  <div>
    <HotDeal />

    <section
      class="page-section promo-brand overflow"
      v-if="state.sale_shocks.length !== 0"
    >
      <div class="header-section">
        <h2 class="header-section-left-side section-title">Khuyến mãi sốc</h2>
        <div class="header-section-right-side">
          <a class="primary-button" href="/products-shock" title="Xem thêm">
            <span class="primary-link_text">Xem thêm</span>
            <i class="dsi dsi-act-arrow-forward"></i>
          </a>
        </div>
      </div>

      <SliderSkeleton :height="190" v-if="state.sale_shocks.length == 0" />
      <div class="dosiin_promotion-brands_slider promotion-brands_slider" v-else>
        <Swiper
          :slidesPerView="'auto'"
          :observer="true"
          :observeParents="true"
          :observeSlideChildren="true"
          :spaceBetween="9"
        >
          <Swiper-slide v-for="(event, i) in state.sale_shocks" :key="i">
            <div class="brand-suggested-brand-item_wrapper brand-item_img-wrapper">
              <div class="brand-item_header">
                <a
                  :href="_brandUrl(event.company_data.seo_name)"
                  :title="event.name"
                  style="display: block"
                >
                  <div class="brand-item_banner-wrapper">
                    <div class="brand-item_img-overlay">
                      <img
                        v-lazy="{
                          src: _bannerImage(event.company_data.banners_main),
                          loading: _bannerDefault,
                          error: _bannerDefault,
                        }"
                        width="339"
                        height="190"
                        class="brand-list-item_img dosiin_w-100"
                      />
                    </div>
                  </div>
                  <div class="brand-item">
                    <template v-if="event.company_data">
                      <ul class="home-new-img-brand">
                        <li class="group-img-circle">
                          <BrandLogo
                            :brand="event.company_data"
                            :width="80"
                            :height="80"
                            imageClass="user-avatar img-circle"
                          />
                        </li>
                      </ul>
                    </template>
                    <div class="brand-item_promo">
                      <i class="promo-icon"></i>
                      {{ event.name }}
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </Swiper-slide>
        </Swiper>
      </div>
    </section>

    <Intersection @onIntersection="onIntersectionCompaniesSale()">
      <section class="page-section brand_on-promo overflow" id="dosiin_brand-sale-group">
        <div class="brandSuggest">
          <h3 class="title">Giảm giá cho bạn</h3>
        </div>
        <ul class="time-period-nav-bar">
          <li
            class="time-period_item"
            :class="{
              active:
                Object.keys(route.query).length == 0 ||
                !route.query.sale_to ||
                route.query.sale_to == '30',
            }"
            @click="changeBrandDiscount({ sale_from: '10', sale_to: '30' })"
            title="Giảm giá 10%-30%"
          >
            <a class="cm-ajax cm-history">10%-30%</a>
          </li>
          <li
            class="time-period_item"
            :class="{
              active: route.query.sale_to == '50',
            }"
            @click="changeBrandDiscount({ sale_from: '30', sale_to: '50' })"
            title="Giảm giá 30%-50%"
          >
            <a class="cm-ajax cm-history">30%-50%</a>
          </li>
          <li
            class="time-period_item"
            :class="{
              active: route.query.sale_to == '80',
            }"
            @click="changeBrandDiscount({ sale_from: '50', sale_to: '80' })"
            title="Giảm giá 50%-80%"
          >
            <a class="cm-ajax cm-history">50%-80%</a>
          </li>
        </ul>

        <SkeletonBrandsSale v-if="state.sale_brands.length == 0" />
        <Swiper
          class="brand-list dosiin_modal-brand-swiper"
          :slidesPerView="5"
          :observer="true"
          :observeParents="true"
          :observeSlideChildren="true"
          :spaceBetween="9"
          v-else
        >
          <Swiper-slide
            v-for="(brand, i) in state.sale_brands"
            :key="i"
            class="brand-modal_item"
            style="width: 23% !important"
          >
            <a :href="_brandUrl(brand.company_data.seo_name)" :title="brand.company">
              <div class="brand_logo">
                <BrandLogo :brand="brand" :width="62" :height="62" />
              </div>
              <h5
                class="brand-name truncate"
                :title="brand.company"
                v-text="brand.company"
              ></h5>
              <div class="label-promo">
                <i class="dsi dsi-s16 dsi-act-fill-coupons"></i
                ><span class="text-pink-gradient">{{ brand.percent }}%</span>
              </div>
            </a>
          </Swiper-slide>
        </Swiper>
      </section>
    </Intersection>

    <Intersection @onIntersection="onIntersectionProducts1030()">
      <ProductsOnSaleSlider
        wrapperClass="page-section product_on-promo overflow"
        title="Giảm giá 10-30%"
        :link="`/products?on_sale_from=10&on_sale_to=30`"
        banner_discount="https://dosi-in.com/images/assets/icons/discount-0--30.jpg"
        gridClass="grid-33"
        :products="state.sale_products_1030"
      />
    </Intersection>

    <Intersection @onIntersection="onIntersectionProducts3050()">
      <ProductsOnSaleSlider
        wrapperClass="page-section product_on-promo overflow"
        title="Giảm giá 30-50%"
        :link="`/products?on_sale_from=30&on_sale_to=50`"
        banner_discount="https://dosi-in.com/images/assets/icons/discount-30-50.jpg"
        gridClass="grid-33"
        :products="state.sale_products_3050"
      />
    </Intersection>

    <Intersection @onIntersection="onIntersectionProducts5080()">
      <ProductsOnSaleSlider
        wrapperClass="page-section product_on-promo overflow"
        title="Giảm giá 50-80%"
        :link="`/products?on_sale_from=50&on_sale_to=80`"
        banner_discount="https://dosi-in.com/images/assets/icons/discount-50-80.jpg"
        gridClass="grid-33"
        :products="state.sale_products_5080"
      />
    </Intersection>
  </div>
</template>

<script>
import { inject } from "vue";
import HotDeal from "@/components/events/HotDeal";
import ProductsOnSaleSlider from "@/components/sliders/ProductsOnSale";
import { useRoute, useRouter } from "vue-router";
import BrandServices from "@/services/brand";
import SkeletonBrandsSale from "@/components/skeletons/sliders/BrandsSale";
import SliderSkeleton from "@/components/skeletons/sliders/MainSliderMB";

export default {
  name: "on-sale",
  components: {
    ProductsOnSaleSlider,
    HotDeal,
    SkeletonBrandsSale,
    SliderSkeleton,
  },
  setup() {
    const currentUser = inject("currentUser");
    const state = inject("state");
    const onIntersectionCompaniesSale = inject("onIntersectionCompaniesSale");
    const onIntersectionBrandPromotion = inject("onIntersectionBrandPromotion");
    const onIntersectionProducts1030 = inject("onIntersectionProducts1030");
    const onIntersectionProducts3050 = inject("onIntersectionProducts3050");
    const onIntersectionProducts5080 = inject("onIntersectionProducts5080");
    const router = useRouter();
    const route = useRoute();

    async function changeBrandDiscount(query) {
      if (query) {
        state.sale_brands = "";
        router.push({ query: { sale_from: query.sale_from, sale_to: query.sale_to } });
        const response = await BrandServices.fetchBrandsSale(query);
        if (response && response.data) {
          state.sale_brands = response.data.companies;
        }
      }
    }

    return {
      state,
      route,
      router,
      currentUser,
      onIntersectionCompaniesSale,
      onIntersectionBrandPromotion,
      onIntersectionProducts1030,
      onIntersectionProducts3050,
      onIntersectionProducts5080,
      changeBrandDiscount,
    };
  },
};
</script>
<style>
.brand_on-promo .brand-list .brand-modal_item {
  width: 23% !important;
}
.brand_on-promo .time-period_item {
  cursor: pointer;
}
.brand_on-promo .brand-list .brand-logo_img {
  width: 62px !important;
  height: 62px !important;
}
</style>
