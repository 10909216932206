<template>
  <Skeleton
    :wrapperClass="wrapperClass"
    :gridClass="gridClass"
    ref="skeleton"
    :number="Math.ceil(options.slidesPerView)"
    v-if="products.length === 0"
  />

  <div v-else :class="wrapperClass">
    <div class="header-section">
      <h3 class="header-section-left-side section-title" v-text="title"></h3>
      <div class="header-section-right-side">
        <router-link class="primary-button" title="Xem thêm" :to="link">
          <span class="primary-link_text">Xem thêm</span>
          <i class="dsi dsi-act-arrow-forward"></i>
        </router-link>
      </div>
    </div>

    <div class="slidePromoProduct dosiin_promo-product-sock-swiper" v-if="layout == 4">
      <Swiper
        :modules="modules"
        :slidesPerView="options.slidesPerView"
        :slidesPerGroup="options.slidesPerGroup"
        :spaceBetween="options.spaceBetween"
        :navigation="options.navigation"
        :pagination="pagination"
        class="overflow-unset"
      >
        <SwiperSlide class="brand-modal_item">
          <img class="bannerImg" :src="banner_discount" width="256" height="256" alt="" />
        </SwiperSlide>
        <SwiperSlide
          v-for="(product, index) in products"
          :key="index"
          class="brand-modal_item"
        >
          <ProductItem :product="product" />
        </SwiperSlide>

        <div
          v-if="options.navigation"
          class="swiper-button-next dosiin_swiper-button dosiin_position-button-next"
        ></div>
        <div
          v-if="options.navigation"
          class="swiper-button-prev dosiin_swiper-button dosiin_position-button-prev"
        ></div>
      </Swiper>
    </div>

    <div class="promotion-home-sale-inner" v-else>
      <!-- <div class="promotion-home-sale-img">
        <img :src="banner_discount" width="259" height="474" alt="" />
      </div> -->
      <div class="dosiin_position-relative promotion-home-sale-slider">
        <Swiper
          :modules="modules"
          :slidesPerView="options.slidesPerView"
          :slidesPerGroup="options.slidesPerGroup"
          :spaceBetween="options.spaceBetween"
          :navigation="options.navigation"
          :pagination="pagination"
          class="dosiin_home_sale_promotion-10-30 overflow dosiin_position-unset"
        >
          <SwiperSlide class="">
            <div class="product-item overflow">
              <img
                class="bannerImg"
                :src="banner_discount"
                style="width: 100%; max-height: 475px; display: block"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide v-for="(product, index) in products" :key="index">
            <ProductItem :product="product" :showRating="true" />
          </SwiperSlide>
          <div
            v-if="options.navigation"
            class="swiper-button-next dosiin_swiper-button dosiin_position-button-next"
          ></div>
          <div
            v-if="options.navigation"
            class="swiper-button-prev dosiin_swiper-button"
          ></div>
        </Swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs, ref, watch } from "vue";
import ProductItem from "@/components/product/ProductItem";
import Skeleton from "@/components/skeletons/sliders/ProductsHorizon";
import { Pagination, Navigation } from "swiper";
export default {
  components: {
    ProductItem,
    Skeleton,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    wrapperClass: {
      type: String,
      default: "",
    },
    gridClass: {
      type: String,
      default: "grid-33",
    },
    products: {
      type: Array,
      require: true,
      default: [],
    },
    link: {
      type: String,
      default: "/",
    },
    banner_discount: {
      type: String,
      default: "https://dosi-in.com/images/assets/icons/discount-0--30.jpg",
    },
    options: {
      type: Object,
      default: {
        slidesPerView: 2.5,
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
        spaceBetween: 9,
        navigation: false,
      },
    },
  },
  setup(props) {
    const options = {};
    const skeleton = ref(null);
    const paginationClass =
      "dosiin_slider-pagination-" + btoa(Math.random().toString()).substr(10, 5);
    const pagination = {
      el: "." + paginationClass,
      clickable: true,
      renderBullet: (index, className) =>
        `<span data="${index}" class="swipable-slider_indicator ${className}"></span>`,
    };

    return {
      options,
      skeleton,
      paginationClass,
      ...toRefs(props),
      pagination,
      modules: [Pagination, Navigation],
    };
  },
};
</script>
<style>
/* .swiper {
  overflow: initial !important;
} */
.promotion-home-sale-slider {
  width: 100%;
}
</style>
