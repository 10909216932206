<template>
  <div class="hotdeal-section" v-if="!dosiinEmptyObject(state.event)">
    <div class="hotdeal-brand-wrapper">
      <h2 class="hotdeal_title">
        <span class="text-pink-gradient">Sale độc quyền</span>
      </h2>

      <div class="hotdeal-brand">
        <div class="hotdeal-brand_img-wrapper">
          <template v-for="(brand, i) in state.brands">
            <div :class="['brand_img-' + (i + 1)]" :key="i" v-if="i < 4">
              <BrandLogo :brand="brand" :width="24" :height="24" />
              <span v-if="i === 3 && state.brands_count > 4"
                >{{ state.brands_count - 4 }}+</span
              >
            </div>
          </template>
        </div>

        <div class="hotdeal-brand_info">
          <h5 class="hotdeal-brand_title" v-text="state.event.name"></h5>
          <h6 class="hotdeal-brand_subtitle" v-if="state.event.companies != 0">
            {{ state.brands_count }} Thương hiệu
          </h6>
        </div>
      </div>

      <vue-countdown
        :time="
          state.event.time_end * 1000 - currentTime > 0
            ? state.event.time_end * 1000 - currentTime
            : 0
        "
        v-slot="{ days, hours, minutes, seconds }"
      >
        <ul class="dosiin_countdown hotdeal-countdown">
          <li class="dosiin_countdown_days">
            <div class="number" v-text="_addLeadingZero(days)"></div>
            <span>Ngày</span>
          </li>
          <li class="dosiin_countdown_hours">
            <div class="number" v-text="_addLeadingZero(hours)"></div>
            <span>Giờ</span>
          </li>
          <li class="dosiin_countdown_minutes">
            <div class="number" v-text="_addLeadingZero(minutes)"></div>
            <span>Phút</span>
          </li>
          <li class="dosiin_countdown_seconds">
            <div class="number" v-text="_addLeadingZero(seconds)"></div>
            <span>Giây </span>
          </li>
        </ul>
      </vue-countdown>

      <div class="hotdeal_btn-wrapper">
        <router-link
          :to="{ name: 'exclusive', query: { event_id: state.event.id } }"
          class="primary-button"
          :title="state.event.name"
        >
          <span class="primary-link_text">Xem thêm sản phẩm</span>
          <i class="dsi dsi-act-arrow-forward"></i>
        </router-link>
      </div>
    </div>

    <div class="product-items-exclusive">
      <Swiper
        class="dosiin_swiper-effect-cards"
        :loop="true"
        :effect="'cards'"
        :grabCursor="true"
        :autoplay="{
          delay: 3500,
        }"
        :modules="modules"
      >
        <SwiperSlide v-for="(product, i) in state.products" :key="i">
          <ProductItem :product="product" />
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>

<script>
import { reactive, inject } from "vue";
import HotDealSkeleton from "@/components/skeletons/events/HotDeal";
import EventData from "@/services/event";
import ProductItem from "@/components/product/ProductItem";
import { EffectCards } from "swiper";

export default {
  components: {
    HotDealSkeleton,
    ProductItem,
  },
  setup() {
    const currentTime = inject("currentTime");
    const state = reactive({
      loading: true,
      brands: [],
      event: {},
      products: [],
      brands_count: 0,
    });

    getExclusive();
    async function getExclusive() {
      const response = await EventData.fetchExclusive();
      if (response.data) {
        state.loading = false;
        state.event = response.data.event;
        state.brands_count = response.data.event
          ? response.data.event.companies_count
          : 0;
        state.products = response.data.products;
        state.brands = response.data.event
          ? response.data.event.companies.slice(0, 4)
          : [];
      }
    }

    return {
      state,
      currentTime,
      modules: [EffectCards],
    };
  },
};
</script>
